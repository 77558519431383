<template>
    <div>
        <empty/>
    </div>
</template>

<script>
import empty from '@/components/empty.vue'
export default {
    data() {
        return {

        };
    },
    // 注册子组件
    components:{
        empty
    },
    created() {

    },
    mounted() {

    },
    methods: {

    },
    computed:{

    }
};
</script>

<style scoped>

</style>
