<template>
  <div class="empty_wrap">
    <div class="content">
      <img src="@/assets/img/empty.png" alt="" />
      <p class="tip">网站正在建设中...敬请期待</p>
      <el-button type="primary" @click="goHome">返回首页</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
      // 回到首页
      goHome(){
          this.$router.push('/index')
      }
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.empty_wrap {
  width: 100%;
  min-height: 800px;
  overflow-x: auto;
  background: #ffffff;
  position: relative;
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
      width: 442px;

    img {
      width: 442px;
      height: 339px;
    }
    .tip {
    //   text-align: center;
      height: 28px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 28px;
      color: rgba(0, 0, 0, 0.45);
      opacity: 1;
    }
  }
}
</style>
